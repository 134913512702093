import { AuthenticationService } from "@empowerment/authenticationservice/";
import { SeverityLevel, TelemetryService } from "@empowerment/telemetry";
import { createContext, useEffect, useState } from "react";
import { authScopeConfig } from "~/config/authScopeConfig";
import { msalConfig } from "~/config/msalConfig";
import type { AuthProviderState } from "~/models/AuthProviderState";
import { getInitials } from "~/utils/stringUtils";
import { TelemetryServiceTrackTraceInformation } from "~/utils/telemetryUtils/telemetryUtils";

const authProviderContext: AuthProviderState = {
    state: "NotLoaded",
    user: undefined,
    authUserInitializedInTelemetry: false,
};

// Create the auth context
const AuthenticationContext = createContext<AuthProviderState>(authProviderContext);

type AuthenticationProps = {
    authUserInitializedInTelemetry: boolean;
    setAuthUserInitializedInTelemetry: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
};

function AuthenticationContextProvider({
    authUserInitializedInTelemetry,
    setAuthUserInitializedInTelemetry,
    children,
}: AuthenticationProps): JSX.Element {
    // the value that will be given to the context
    const [authProvContext, setAuthProvContext] = useState<AuthProviderState>(authProviderContext);

    // Initialize the auth and get the logged in user's details
    useEffect(() => {
        async function authenticateUser(): Promise<void> {
            TelemetryServiceTrackTraceInformation(
                `Auth Init - msal : ${JSON.stringify(msalConfig(), null, 2)} , endpoint scopes ${JSON.stringify(
                    authScopeConfig(),
                    null,
                    2
                )}`,
                SeverityLevel.Information,
                { response: "" }
            );
            await AuthenticationService.initialize({
                config: msalConfig(),
                endpointToScopes: authScopeConfig(),
            });
            const userAccount = AuthenticationService.getActiveAccount();
            const userName = userAccount?.username;
            TelemetryServiceTrackTraceInformation(
                `Auth getaccount for ${userName ?? "undefined"}`,
                SeverityLevel.Information,
                { response: `${JSON.stringify(userAccount, null, 2)}` }
            );
            setAuthProvContext((prev) => ({
                ...prev,
                state: "Loaded",
                user: {
                    name: userAccount?.name!,
                    email: userName!,
                    initials: getInitials(userAccount?.name),
                },
            }));
            if (userName !== undefined) {
                TelemetryService.setAuthenticatedUserContext(userName);
                setAuthUserInitializedInTelemetry(true);
            }
        }
        void authenticateUser();
    }, [authProvContext.user?.email, setAuthUserInitializedInTelemetry]);

    return (
        <AuthenticationContext.Provider value={{ ...authProvContext, authUserInitializedInTelemetry }}>
            {children}
        </AuthenticationContext.Provider>
    );
}

export { AuthenticationContext, AuthenticationContextProvider };
